export default {
  us: {
    languages: {
      us: 'English (United States)',
      es: 'Spanish (United States)',
      mx: 'Spanish (México)',
      co: 'Spanish (Colombia)',
      cl: 'Spanish (Chile)',
      pe: 'Spanish (Perú)',
      ec: 'Spanish (Ecuador)'
    },
    routes: {
      properties: 'homes-and-condos',
      projects: 'new-projects',
      blog: 'blog',
      blogSearch: 'search',
      category: 'c',
      categories: {
        present: 'present',
        qualityOfLife: 'quality-of-life',
        investment: 'investment',
        miamiTourism: 'miami-tourism'
      },
      institutional: 'pfs',
      calculator: 'mortgage-calculator',
      marketReport: 'market-report',
      county: 'county',
      account: 'account',
      profile: 'profile',
      savedProperties: 'saved-properties',
      savedSearches: 'saved-searches',
      notifications: 'notifications',
      resetPassword: 'reset-password',
      elite: 'elite',
      test: 'test-page'
    },
    header: {
      signUp: 'Sign Up'
    },
    contactForm: {
      title: 'Invest in Miami',
      description:
        'Leave us your details and receive personalized advice on how to invest in\n      Miami'
    },
    menu: {
      home: 'Home',
      search: 'Search',
      developments: 'Developments',
      services: 'Services',
      aboutUs: 'About Us',
      blog: 'Blog',
      resources: 'Resources',
      contactUs: 'Contact',
      properties: 'Used Properties',
      newProperties: 'New Properties',
      buildings: 'Search by buildings',
      zone: 'Search by zone',
      inListing: 'PFS Listing',
      financing: 'Financing',
      financingLink: '/pfs/loans-for-foreigners',
      propertyManagement: 'Property Management',
      propertyManagementLink: '/pfs/property-management',
      accounting: 'Accounting, Insurance and Migration',
      accountingLink: '/pfs/accounting-and-legal-advice',
      insurance: 'Insurance',
      migration: 'Migration',
      rentInDollars: 'Rent in Dollars',
      rentInDollarsLink: '/pfs/rent-in-dollars',
      aboutUsLink: '/pfs/who-we-are',
      pfs: 'PFS',
      institutional: 'Institutional',
      contactUsLink: '/pfs/contact-us'
    },
    common: {
      translatedVersion: 'Spanish version',
      searchResults: 'Search results',
      date: 'Date',
      mightInterestYou: 'Might interest you:',
      bestHousesForSale: 'Best houses for sale',
      cookiesMessage:
        'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you agree with it.',
      closeCookiesMessage: 'Close cookies message popup',
      contactMenu: 'Contact',
      accountMenu: 'Account',
      signInSuccess: 'Signed up successfully',
      signUpSuccess: 'Signed up successfully',
      accept: 'Accept',
      select: 'Select',
      on: 'On',
      off: 'Off',
      done: 'Done',
      goBack: 'Go back',
      goBackHome: 'Back to Home',
      delete: 'Delete',
      menu: 'Menu',
      autocomplete: 'Autocomplete',
      close: 'Close',
      closePopup: 'Close popup',
      closeFilters: 'Close filter',
      noResults: 'No results',
      noOptionsAvailable: 'No options available',
      range: '{0} to {1}',
      by: 'by {0}',
      fromN: 'From {0}',
      toN: 'To {0}',
      photoOf: 'Photo of {0}',
      errors: {
        unknown: 'Error'
      },
      viewMore: 'View more',
      viewLess: 'View less',
      oops: 'Oops!',
      noItemsFound: 'No items found',
      pageNotFound: 'The page you are looking for does not exist',
      tryAdjustingFilters: 'Try adjusting your filters',
      previous: 'Previous',
      next: 'Next',
      perMonthNumber: '/month',
      link: 'Link',
      yes: 'Yes',
      no: 'No',
      avgPrice: 'Avg. Price',
      avgPriceSqft: 'Avg. Price / SQFT',
      infoDisclaimer:
        "IDX information is provided exclusively for personal, non-commercial use, and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information is deemed reliable but not guaranteed.\nThe listing broker's offer of compensation is made only to participants of  the MLS where the listing is filed."
    },
    elite: {
      menu: {
        launches: 'Launches',
        calendar: 'Miami Agenda'
      },
      auth: {
        login: 'Login',
        profile: 'User'
      },
      title: 'Elite Club - PFS Realty',
      description:
        'Know the benefits of being part of the PFS Realty Elite Club',
      intro: `Investing in Miami is an excellent decision, having done so with <strong>PFS Realty Group</strong> opens the doors to great opportunities and benefits.`,
      welcome: 'Welcome to PFS Elite Club',
      benefits:
        'Learn about some of your <span class="font-bodoni">Benefits</span>',
      eventCalendar: '<span class="font-bodoni">Event Calendar</span>',
      footer: 'Copyright© 2024 PFS Realty Group. All rights reserved.'
    },
    blog: {
      title: 'Opportunities in Miami and South Florida',
      description:
        'Opportunities in Miami and South Florida. Know the investment opportunities, to live or enjoy in Miami and South Florida.',
      subtitle:
        'Know the investment opportunities, to live or enjoy in Miami and South Florida.',
      contentTable: 'Table of contents',
      category: 'Category',
      categories: {
        present: 'Present',
        qualityOfLife: 'Quality of Life',
        investment: 'Investment',
        miamiTourism: 'Miami Tourism'
      },
      categoryArticles: 'Category articles',
      searchResults: 'Search results',
      searchHint: 'Adjust your search to find what you are looking for',
      relatedPosts: 'Related posts',
      seo: {
        title: 'Blog | PFS Realty'
      }
    },
    auth: {
      title: 'Welcome to PFS',
      goToProfile: 'Go to profile',
      signIn: 'Sign in',
      signOut: 'Sign out',
      signUp: 'Sign up',
      forgotPassword: 'Forgot password',
      forgotPasswordHelp:
        'Enter your email and we will send you a link to reset your password.',
      forgotPasswordSubmit: 'Submit',
      forgotPasswordQuestion: 'Forgot password?',
      knowPasswordQuestion: 'Know your password?',
      forgotPasswordSuccess:
        'An email has been sent with instructions to reset your password.',
      passwordReset: 'Password reset',
      passwordResetSuccess: 'Password reset successfully',
      passwordResetHelp: 'Enter your new password',
      email: 'Email',
      emailPlaceholder: 'Enter email',
      emailRequired: 'Email is required',
      emailInvalid: 'Enter a valid email',
      password: 'Password',
      passwordPlaceholder: 'Enter password',
      createPasswordPlaceholder: 'Create password',
      passwordRequired: 'Password is required',
      passwordInvalid: 'Enter a valid password',
      loginToSavePropertyHint:
        'Sign in or create a new account to save a property',
      signInErrors: {
        '400': 'Internal error',
        '401': 'Invalid email or password',
        '403': 'You must verify your email',
        '500': 'Server error',
        unknown: 'Error'
      },
      signUpErrors: {
        '400': 'Internal error',
        '403': 'Invalid email or password',
        '409': 'Email already in use',
        '500': 'Server error',
        unknown: 'Error'
      },
      resetPasswordErrors: {
        '10': 'Invalid email',
        '40': 'User not found',
        '400': 'User not found',
        '403': 'Invalid email',
        '500': 'Server error',
        unknown: 'Error'
      },
      setNewPasswordErrors: {
        '10': 'Invalid email',
        '40': 'User not found',
        '400': 'User not found',
        '403': 'Invalid email',
        '500': 'Server error',
        unknown: 'Error'
      },
      submitSignIn: 'Sign in',
      submitSignUp: 'Submit',
      charactersAmountHint: 'At least 8 characters',
      characterMixHint: 'Mix letters and numbers',
      specialCharacterHint: 'At least one special character',
      signInDisclaimer:
        'By signing up you agree to our {0}terms and conditions{1}.',
      orConnectWith: 'Or connect with'
    },
    home: {
      seo: {
        title: 'PFS Realty Group',
        description:
          'At PFS Realty find real estate and properties for sale and rent in Florida.'
      },
      hero: {
        title: 'Homes and Condos for Sale and Rent in Miami',
        p: 'Properties',
        f: 'Financing',
        s: 'Services'
      },
      used: 'Used',
      new: 'New',
      rent: 'Rent',
      buy: 'Used',
      location: 'Search by city, ZIP code, or county',
      currentLocation: 'Current Location',
      contactFormTitle: 'Contact us for more information!',
      contactFormText:
        'Access the best investment opportunities, market updates, and specialized content.',
      find_property_title: 'Find your property in Miami',
      find_property_text:
        'We have a wide portfolio of properties that are perfect for you',
      properties_title: 'Our portfolio of properties',
      propertiesPortfolio: 'Homes and Condos for sale and rent in Miami',
      apartmentsPortfolioDescription: 'Our apartments portfolio',
      housesPortfolioDescription: 'Our houses portfolio',
      clients: {
        title:
          '2,000 clients from around the world have invested in Miami thanks to PFS',
        features: {
          one: {
            title:
              '21 years of experience in property sales in the United States',
            description:
              'Over two decades of experience in property sales in the United States, providing reliable service and successful results.'
          },
          two: {
            title: '65 advisors in 12 countries in the Americas and Europe',
            description:
              'A wide network of 65 advisors strategically located in the Americas and Europe to maximize your real estate investment opportunities.'
          },
          three: {
            title: 'More than 2,000 satisfied customers trust us',
            description:
              'More than 2,000 satisfied customers support our commitment to exceptional service and satisfactory results in real estate investments. Join us and experience success with PFS Realty.'
          }
        }
      },
      services: {
        title: 'Integrated Services',
        'property_title:': 'Acquire the Perfect Property',
        properties: {
          title: 'Acquire the Perfect Property',
          description:
            'Guaranteed purchase of new and high-quality used real estate. We have extensive experience and knowledge to find the ideal property.'
        },
        financing: {
          title: 'Customized Financing Solutions',
          description:
            'We offer flexible and personalized mortgage loans for foreigners with a minimum 30% down payment. We tailor the terms to your needs.'
        },
        extra_income: {
          title: 'Generate Extra Income through Renting',
          description:
            'Guaranteed property rental with tenants who generate income in dollars. We comprehensively manage to maximize profitability.'
        },
        management: {
          title: 'Property Management',
          description:
            'Professional care, preventive and corrective maintenance, and efficient management of your real estate investment. We optimize costs and benefits.'
        },
        legal: {
          title: 'Comprehensive Legal and Accounting Advisory',
          description:
            'Interdisciplinary team of highly specialized professionals in all legal and accounting aspects. We ensure regulatory compliance.'
        }
      },
      events: {
        title: 'Events',
        formMessage: 'Fill the form to reserve a spot in our event',
        registerButton: 'Book Entry'
      },
      testimonialsTitle: 'Testimonials',
      news_title: 'News',
      popupFormTitle:
        'Discover the secret to achieving your real estate dream in Miami and Orlando!',
      popupFormMessage:
        "Subscribe to our newsletter and gain access to exclusive tips, market trends, and unique opportunities. Don't miss this chance to boost your success in real estate!"
    },
    account: {
      accountSettings: 'Account Settings',
      profile: 'Profile',
      profileHint: 'Customize your account and update your login preferences',
      notifications: 'Notifications',
      notificationsSectionHint: 'Manage your notifications preferences',
      notificationsHint: 'Turn on or off notifications preferences',
      searchNotificationsHint: 'Turn on notifications for this search',
      enableAllNotifications: 'Enable all notifications',
      propertyNotificationsEnabled: 'Notifications enabled for this property',
      propertyNotificationsDisabled: 'Notifications disabled for this property',
      savedProperties: 'Saved Properties',
      savedPropertiesHint:
        "Keep track of the homes you like and the ones you've seen recently.",
      toggleNotificationsHint:
        'Use the toggle switch to turn on or off notifications',
      savedSearches: 'Saved Searches',
      recentlyViewedProperties: 'Recently Viewed',
      personalInfo: 'Personal Information',
      name: 'Name',
      namePlaceholder: 'Enter your name',
      lastName: 'Last Name',
      lastNamePlaceholder: 'Enter your last name',
      picture: 'Picture',
      pictureHint: 'Upload a profile picture',
      accessAndSecurity: 'Access and Security',
      email: 'Email',
      emailPlaceholder: 'Enter your email',
      password: 'Password',
      passwordPlaceholder: 'Enter your password',
      noSavedProperties: 'Get updates for your saved properties',
      searchProperties: 'Search Properties',
      untitledSavedSearch: 'Untitled search',
      noSavedSearches: 'Get updates for your saved searches',
      savedSearchesHint:
        'Saving your searches saves you time and keeps you informed of new updates.',
      changePasswordEmailWithButton:
        'Change your password through your email {button}.',
      changePasswordEmailButton: 'clicking here',
      changePasswordEmailSent:
        'An email has been sent to {email} with instructions to change your password.'
    },
    search: {
      previous: 'Previous',
      next: 'Next',
      title: 'Search for homes, condos and other real estate',
      searchPlaceholder: 'Search by city, zip code or county',
      searchLabel: 'Search',
      nResults: '{0} results',
      nActiveProperties: '{0} active',
      resetFilters: 'Reset search',
      resetFiltersLong: 'Remove filters',
      acceptFilters: 'Apply',
      saveSearch: 'Save search',
      searchSaved: 'Search saved',
      map: 'Map',
      listView: 'List',
      county: 'County',
      city: 'City',
      zipcode: 'ZIP code',
      neighborhood: 'Neighborhood',
      allCounties: 'All counties',
      allCities: 'All cities',
      allZipcodes: 'All ZIP codes',
      allNeighborhoods: 'All neighborhoods',
      from: 'From',
      to: 'To',
      noMin: 'No min.',
      noMax: 'No max.',
      bedroomsCard: 'be',
      bathsFullCard: 'ba',
      bedroomsShort: 'be',
      bathsFullShort: 'ba',
      areaShort: 'sqft',
      areaOf: 'area of',
      postalCodeShort: 'ZIP code {0}',
      filters: 'Filters',
      propertyType: 'Home type',
      propertyTypes: 'Home types',
      selectPropertyType: 'Select home type',
      selectHouseType: 'Select type',
      selectCondoType: 'Select type',
      selectPropertySubTypes: 'Select type',
      property: 'Property',
      properties: 'Homes and condos',
      projects: 'Projects',
      houseType: 'Home',
      housesType: 'Homes',
      condoType: 'Apartment',
      condosType: 'Apartments',
      house: 'House',
      houses: 'Houses',
      singleFamilyResidence: 'Single Family',
      singleFamilyResidences: 'Single Families',
      townhouse: 'Townhouse',
      townhouses: 'Townhouses',
      villa: 'Villa',
      villas: 'Villas',
      condominium: 'Condo',
      condominiums: 'Condos',
      apartment: 'Apartment',
      apartments: 'Apartments',
      multiFamily: 'Multi Family',
      multiFamilies: 'Multi Families',
      building: 'Building',
      buildings: 'In Buildings',
      price: 'Price',
      priceFrom: 'From',
      priceTo: 'To',
      priceFromShort: 'Min. price',
      priceToShort: 'Max. price',
      areas: 'areas',
      bedrooms: 'Beds',
      bedroomsFrom: 'From',
      bedroomsTo: 'To',
      nOrMoreBedrooms: '{0} or more beds',
      nOrLessBedrooms: 'up to {0} beds',
      nBedrooms: '{0} beds',
      nRooms: '{0} rooms',
      moreOptions: 'More options',
      showPlaces: 'Show places',
      bedroomsAndBathrooms: 'Beds & Baths',
      bathrooms: 'Baths',
      bathroomsFrom: 'From',
      bathroomsTo: 'To',
      nOrMoreBathrooms: '{0} or more baths',
      nOrLessBathrooms: 'up to {0} baths',
      nBathrooms: '{0} baths',
      nArea: '{0} sqft.',
      nAreaShort: '{0} sqft.',
      nAreaM2: '{0} m²',
      nYear: '{0}',
      area: 'Area',
      areaFrom: 'Area from',
      areaTo: 'Area to',
      year: 'Year',
      yearFrom: 'Year from',
      yearTo: 'Year to',
      furnished: 'Furnished',
      view: 'View',
      viewOptions: {
        any: 'Any',
        ocean: 'Ocean'
      },
      condoCost: 'HOA Max',
      condoCostFrom: 'Condo cost from',
      condoCostTo: 'Condo cost to',
      hopa: 'HOPA',
      dock: 'Dock',
      shortTermLease: 'Short-term lease',
      inListing: 'In listing',
      inListingItems: '{0} in listing',
      pendingAndUnderContract: 'Pending & Under contract',
      pendingAndUnderContractProperties: 'Pending & Under contract properties',
      advancedSearch: 'Advanced search',
      viewMap: 'View map',
      viewSatellite: 'View satellite',
      viewBuildings: 'View buildings',
      view2D: '2D',
      view3D: '3D',
      sortBy: 'Sort',
      visitSite: 'Visit website',
      mapLayers: {
        newProjects: 'New projects',
        beaches: 'Beaches',
        malls: 'Malls',
        restaurants: 'Restaurants',
        collegeUniversities: 'Universities',
        publicSchools: 'Public Schools',
        privateSchools: 'Private Schools',
        hospitals: 'Hospitals'
      },
      sortByOptions: {
        mostRecent: 'Most recent',
        oldest: 'Oldest',
        highestPrice: 'Highest price',
        lowestPrice: 'Lowest price',
        fewestBathrooms: 'Fewest bathrooms',
        mostBathrooms: 'Most bathrooms',
        fewestBedrooms: 'Fewest bedrooms',
        mostBedrooms: 'Most bedrooms',
        smallestArea: 'Smallest area',
        largestArea: 'Largest area',
        firstToFinish: 'First to finish',
        lastToFinish: 'Last to finish'
      },
      buyTypeOptions: {
        used: 'Used',
        new: 'New Constructions',
        rent: 'For Rent',
        buy: 'For Sale',
        sold: 'Sold',
        rented: 'Rented'
      },
      hopaOptions: {
        any: 'Any',
        'hopa-eligible': 'HOPA eligible',
        'no-hopa': 'Non-HOPA'
      },
      availableItems: '{items} for sale and rent',
      soldItems: 'Sold {items}',
      soldItems_female: 'Sold {items}',
      rentedItems: 'Rented {items}',
      rentedItems_female: 'Rented {items}',
      newItems: 'New {items} for sale',
      newItems_female: 'New {items} for sale',
      usedItems: 'Used {items} for sale',
      usedItems_female: 'Used {items} for sale',
      itemsForSale: '{items} for sale',
      itemsForRent: '{items} for rent',
      rentSearchResults: '{search} {items} for Rent',
      buySearchResults: '{search} {items} for Sale',
      soldSearchResults: '{search} Sold {items}',
      rentedSearchResults: '{search} Rented {items}',
      buyRentSearchResults: '{search} {items} for Sale and Rent',
      rentSearchResultsNearYou: '{0} for Rent near you',
      buySearchResultsNearYou: '{0} for Sale near you',
      soldSearchResultsNearYou: 'Sold {0} near you',
      rentedSearchResultsNearYou: 'Rented {0} near you',
      buyRentSearchResultsNearYou: '{0} for Sale and Rent near you',
      rentResults: 'Rental Listing',
      soldResults: 'Sold Properties',
      rentedResults: 'Rented Properties',
      buyResults: 'Properties for sale',
      anyResults: 'Available Properties',
      all: 'All',
      otherItems: 'Nearby properties',
      otherSearches: 'Other people also searched for',
      removeBoundary: 'Remove boundary',
      addBoundary: 'Add new boundary',
      cancelBoundaryDraw: 'Cancel',
      otherCities: 'Other cities',
      sortByCity: 'Cities'
    },
    paramsKeys: {
      propertyType: 'pt',
      propertySubType: 'ps',
      projectType: 'pj',
      price: 'pr',
      bedrooms: 'be',
      bathsFull: 'ba',
      area: 'a',
      year: 'y',
      furnished: 'f',
      view: 'v',
      condoCost: 'cc',
      dock: 'd',
      shortTermLease: 'stl',
      excludePendingAndUnderContract: 'epc',
      inListing: 'il',
      sortBy: 's',
      buyType: 'bt',
      page: 'pg',
      location: 'l',
      county: 'co',
      city: 'ci',
      zipcode: 'zc',
      neighborhood: 'n',
      geolocation: 'g',
      usedProject: 'up',
      hopa: 'h'
    },
    legacyParamsKeys: {
      propertyType: 'type',
      propertySubType: 'properties',
      projectType: 'projects',
      price: 'price',
      bedrooms: 'bedrooms',
      bathsFull: 'bathrooms',
      area: 'area',
      year: 'year',
      furnished: 'furnished',
      view: 'view',
      condoCost: 'hoa',
      dock: 'dock',
      shortTermLease: 'short-term-lease',
      excludePendingAndUnderContract: 'exclude-pending-and-under-contract',
      inListing: 'in-listing',
      sortBy: 'sort',
      buyType: 'type',
      page: 'page',
      location: 'search',
      county: 'county',
      city: 'city',
      zipcode: 'zipcode',
      neighborhood: 'neighborhood',
      geolocation: 'gl',
      usedProject: 'used',
      hopa: 'hopa'
    },
    paramsAliases: {
      RES: 'buy',
      RNT: 'rent',
      SOL: 'sold',
      RTD: 'rented',
      house: 'homes',
      singleFamilyResidence: 'single-families',
      townhouse: 'townhouses',
      villa: 'villas',
      condo: 'condos',
      apartment: 'apartments',
      building: 'in-buildings',
      multiFamily: 'multi-families',
      'pre-construction': 'pre-construction',
      'under-construction': 'under-construction',
      built: 'built',
      'on-the-market': 'on-the-market',
      'hopa-eligible': 'hopa',
      'no-hopa': 'no-hopa',
      'yearBuild:desc': 'most-recent',
      'yearBuild:asc': 'oldest',
      'listPrice:desc': 'highest-price',
      'listPrice:asc': 'lowest-price',
      'bathsFull:desc': 'most-bathrooms',
      'bathsFull:asc': 'fewest-bathrooms',
      'bedrooms:desc': 'most-bedrooms',
      'bedrooms:asc': 'fewest-bedrooms',
      'propertyArea:desc': 'largest-area',
      'propertyArea:asc': 'smallest-area',
      'yearCompletion:desc': 'last-to-finish',
      'yearCompletion:asc': 'first-to-finish',
      city: 'cities',
      availability: 'availability',
      pending: 'pending',
      closed: 'closed',
      statistics: 'statistics'
    },
    locationTypes: {
      county: 'County',
      city: 'City',
      zip_code: 'Zip code',
      project: 'Project',
      building: 'Building',
      address: 'Address',
      neighborhood: 'Neighborhood',
      mls_id: 'MLS ID'
    },
    property: {
      save: 'Save',
      saved: 'Saved',
      propertySaved: 'Property saved as favorite',
      propertyRemoved: 'Property removed from favorites',
      backToSearch: 'Back to results',
      backToProject: 'Back to building',
      share: 'Share',
      copyShareLink: 'Copy link',
      previousProperty: 'Previous',
      nextProperty: 'Next',
      bedrooms: 'Beds',
      bathrooms: 'Baths',
      factsAndFeatures: 'Facts & Features',
      interior: 'Interior',
      bedroomsAndBathrooms: 'Bedrooms & Bathrooms',
      bedroomsLong: 'Bedrooms',
      bathroomsLong: 'Bathrooms',
      fullBathrooms: 'Full bathrooms',
      flooring: 'Flooring',
      heating: 'Heating',
      heatingFeatures: 'Heating features',
      cooling: 'Cooling',
      hasCooling: 'Has cooling',
      coolingFeatures: 'Cooling features',
      appliances: 'Appliances',
      appliancesIncluded: 'Appliances included',
      interiorFeatures: 'Interior features',
      otherInteriorFeatures: 'Other interior features',
      virtualTour: 'Virtual tour',
      property: 'Property',
      parking: 'Parking',
      parkingFeatures: 'Parking features',
      poolFeatures: 'Pool features',
      privatePool: 'Private pool',
      exteriorFeatures: 'Exterior features',
      patioAndPorchFeatures: 'Patio & Porch details',
      viewDescription: 'View description',
      otherPropertyInformation: 'Other property information',
      parcelNumber: 'Parcel number',
      attachedToAnotherStructure: 'Attached to another structure',
      otherEquipment: 'Other equipment',
      construction: 'Construction',
      typeAndStyle: 'Type & style',
      homeType: 'Home type',
      materialInformation: 'Material information',
      cibstructionMaterials: 'Construction materials',
      condition: 'Condition',
      propertyCondition: 'Property condition',
      newConstruction: 'New construction',
      type: 'Type',
      yearBuilt: 'Year built',
      communityAndNeighborhood: 'Community & Neighborhood',
      security: 'Security',
      location: 'Location',
      region: 'Region',
      neighborhood: 'Neighborhood',
      hoaAndFinancial: 'HOA & Financial',
      hoa: 'HOA',
      hasHoa: 'Has HOA',
      hoaFee: 'HOA fee',
      amenitiesIncluded: 'Amenities included',
      otherFinancialInformation: 'Other financial information',
      buyerAgencyCompensation: 'Buyer agency compensation',
      contactAgent: 'Contact agent',
      requestShowing: 'Request showing',
      similarProperties: 'Similar properties',
      shareTitle: 'Property on PFS',
      shareText: 'Check out this property on PFS',
      shareLinkCopied: 'Link copied to clipboard',
      areaSqft: 'Area (sqft.)',
      mlsCode: 'MLS #',
      sold: 'Sold',
      sold_female: 'Sold',
      rented: 'Rented',
      rented_female: 'Rented',
      active: 'Active',
      active_female: 'Active',
      estimatedPayment: 'Estimated Payment',
      getPrequalification: 'Calculate your monthly payments'
    },
    project: {
      newProjects: 'New projects',
      usedBuildings: 'Used buildings',
      project: 'Project',
      priceSQFT: 'Price per sqft',
      priceSQFTShort: '$/SQFT',
      SQFT: 'SQFT',
      SQFTShort: 'SQFT',
      preConstruction: 'Pre-construction',
      underConstruction: 'Under construction',
      built: 'Built',
      onTheMarket: 'On the market',
      profile: 'Profile',
      availability: 'Availability',
      pending: 'Pending',
      closed: 'Closed',
      stats: 'Statistics',
      projectDetails: 'Project details',
      propertyDetails: 'Property details',
      floorPlans: 'Floor plans',
      features: 'Features',
      amenities: 'Amenities',
      appliance: 'Appliances',
      heating: 'Heating',
      maintenanceFee: 'Maintenance fee',
      construction: 'Construction',
      garage: 'Garage',
      restrictions: 'Restrictions',
      exterior: 'Exterior',
      parking: 'Parking',
      termsConsidered: 'Terms considered',
      interior: 'Interior',
      petsAllowed: 'Pets allowed',
      flooring: 'Flooring',
      furnished: 'Furnished',
      HOAFee: 'HOA Fee/Quarterly',
      HOPA: '+55 communities',
      hopaEligible: 'Eligible',
      noHopa: 'Non-HOPA',
      cooling: 'Cooling',
      yearBuilt: 'Year built',
      similarProjects: 'Similar projects',
      yearCompletionShort: 'Est. completion',
      stories: 'Stories',
      residences: 'Residences',
      shareTitle: 'Project on PFS',
      shareText: 'Check out this project on PFS',
      shareLinkCopied: 'Link copied to clipboard',
      areaSqft: 'Area (sqft.)',
      sales: 'Sales',
      rentals: 'Rentals',
      unitNumberShort: 'Unit#',
      listPrice: 'List price',
      maintenanceExpenseShort: 'Maint$',
      listDate: 'List date',
      availableCondosForRent: 'Available condos for rent',
      pendingCondosForRent: 'Pending condos for rent',
      closedCondosForRent: 'Rented condos',
      availableCondosForSale: 'Available condos for sale',
      pendingCondosForSale: 'Pending condos for sale',
      closedCondosForSale: 'Sold condos',
      noCondos: '{project} has no condos available with the selected filters',
      condos:
        '{project} has {n} condo(s) with the selected filters with an average price/sqft of {price}',
      statsLabels: {
        averageListPricePerSQFT: 'Average List Price / SQFT',
        minListPricePerSQFT: 'Min List Price / SQFT',
        maxListPricePerSQFT: 'Max List Price / SQFT',
        monthsOfInventory: 'Months of Inventory',
        unitsSoldPast3Months: 'Units Sold within the past 3 months',
        unitsSoldPast6Months: 'Units Sold within the past 6 months',
        unitsSoldPast12Months: 'Units Sold within the past 12 months',
        unitsSoldPast24Months: 'Units Sold within the past 24 months',
        availableUnitsForSale: 'Available Units For Sale',
        TotalUnitsAvailableForSaleP: '% of Total Units Available For Sale',
        minSalesPricePerSQFT_6Months: 'Min Sales Price / SQFT (6 months)',
        maxSalesPricePerSQFT_6Months: 'Max Sales Price / SQFT (6 months)',
        averageSalesPricePerSQFT_3Months:
          'Average Sales Price / SQFT (3 months)',
        averageSalesPricePerSQFT_6Months:
          'Average Sales Price / SQFT (6 months)',
        averageSalesPricePerSQFT_12Months:
          'Average Sales Price / SQFT (12 months)',
        averageSalesPricePerSQFT_24Months:
          'Average Sales Price / SQFT (24 months)',
        availableUnitsForRent: 'Available Units For Rent',
        totalUnitsAvailableForRentP: '% of Total Units Available For Rent',
        unitsRentedPast3Months: 'Units Rented within the past 3 months',
        unitsRentedPast6Months: 'Units Rented within the past 6 months',
        unitsRentedPast12Months: 'Units Rented within the past 12 months',
        unitsRentedPast24Months: 'Units Rented within the past 24 months',
        avgSalesPriceSqft: 'Average Sales Price / SQFT',
        avgRentalsPriceSqft: 'Average Rentals Price / SQFT',
        year: 'Year',
        soldUnits: 'Sold units',
        rentedUnits: 'Rented units',
        nSoldUnits: '{0} sold units',
        informationReliability:
          'Information deemed reliable but not guaranteed.'
      }
    },
    calculator: {
      title: 'Mortgage Calculator',
      monthlyPayments: 'Monthly Payments',
      principalInterest: 'Principal & Interest',
      propertyTax: 'Property Tax',
      associationFee: 'Association Fee',
      insuranceFee: 'Insurance Fee',
      homePrice: 'Home Price',
      propertyTaxYearly: 'Property Tax (yearly)',
      associationFeeMonthly: 'Association Fee (per month)',
      insuranceFeeMonthly: 'Insurance Fee (per month)',
      downPayment: 'Down Payment (%)',
      interestRate: 'Interest Rate (%)',
      interestRatePlaceholder: 'Interest Rate',
      loanType: 'Loan Type',
      loanType10Year: '10-Year Fixed',
      loanType15Year: '15-Year Fixed',
      loanType20Year: '20-Year Fixed',
      loanType30Year: '30-Year Fixed',
      disclaimer: '*The calculations are an estimate.'
    },
    footer: {
      city_label: 'City',
      zip_code_label: 'Zip code',
      county_label: 'County',
      pre_construction_label: 'Preconstruction',
      home_type_label: 'Home type',
      price_label: 'Sale price',
      rent_label: 'Rent price',
      other_cities: 'Other cities',
      other_zipcodes: 'Other zip codes',
      other_types: 'Other types',
      other_counties: 'Other counties',
      other_preconstructions: 'Other preconstructions',
      other_prices: 'Other prices',
      findUs: 'Find us:',
      salesTitle: 'Representatives in:',
      countries:
        'MÉXICO • COLOMBIA • ARGENTINA • SPAIN • VENEZUELA • ECUADOR • CHILE\n            • PERU • CANADA • PORTUGAL',
      interest: 'Of interest',
      termsAndConditions: 'Terms and Conditions',
      termsAndConditionsLink: '/pfs/terms-and-conditions',
      whoWeAre: 'Who We Are',
      allRightsReserved: 'All rights reserved'
    },
    connectors: {
      in: '{0} in {1}',
      with: '{0} with {1}',
      without: '{0} without {1}',
      from: '{0} from {1}',
      to: '{0} to {1}',
      and: '{0} and {1}',
      or: '{0} or {1}',
      of: '{0} of {1}',
      ranging: '{0} ranging {1}',
      ofType: '{0} of type {1}',
      ofPrice: '{1} {0}',
      type: '{0} of type {1}',
      builtIn: '{0} built in {1}',
      builtIn_female: '{0} built in {1}',
      default: '{1} {0}',
      withView: '{0} with {1} view',
      withViewWith: '{0} with view with {1}',
      withViewTo: '{0} with view to {1}',
      withViewToThe: '{0} with view to the {1}',
      withViewToThe_female: '{0} with view to the {1}',
      withViewToThe_female_plural: '{0} with view to the {1}',
      recently: 'Recently {0}'
    },
    institutional: {
      seoTitle: 'PFS Realty Group',
      title: 'Opportunities in Miami and South Florida',
      description:
        'Know the investment opportunities, to live or enjoy in Miami and South Florida.'
    },
    marketReport: {
      title: 'Market Report',
      description:
        'Get the latest real estate market reports in Miami and South Florida.',
      longTitle:
        'Real Estate Market Report in South Florida - PFS Realty Group',
      help: 'Select the area, the type of property, and the rest of the options to see the updated information',
      activeListings: 'Active Listings',
      nActiveListingsIn: '{count} Active Properties in {location}',
      nActiveListings: '{0} Active Properties in all counties',
      recentlySold: 'Recently Sold',
      recentlyRented: 'Recently Rented',
      recentlyClosed: 'Recently Closed',
      priceTrends: 'Price Trends',
      averagePrice: 'Average Prices in {0}',
      buyTypes: {
        sales: 'Sales',
        rentals: 'Rentals'
      }
    },
    pages: {
      neighborhoodsIn: 'Neighborhoods in {0}',
      citiesIn: 'Cities in {0}',
      counties: 'Counties'
    },
    seo: {
      locale: 'us',
      alternateLocale: 'mx',
      defaultTitle: 'PFS Realty Group',
      defaultDescription:
        'At PFS Realty find real estate and properties for sale and rent in Florida.',
      home: 'Home',
      defaultSingle: 'House or apartment',
      singleSale: '{0} for sale',
      singleRent: '{0} for rent',
      singleSaleAlt: '{0} for sale',
      singleRentAlt: '{0} for rent',
      defaultMultiple: 'Homes and condos',
      multipleSale: 'Homes and condos for sale',
      multipleRent: 'Homes and condos for rent',
      project: 'Project',
      projects: 'Projects',
      preConstruction: '{0} in preconstruction',
      underConstruction: '{0} under construction',
      built: 'New {0}',
      onTheMarket: '{0} on the market',
      complementaryTitle:
        'at PFSRealty find real estate and properties for sale and rent.',
      commplementaryDescription:
        'at PFSRealty find real estate, properties, homes and condos for sale and rent.',
      atFlorida: 'at Florida',
      numberArticle: '{0}',
      numberArticleSingle: '{0}',
      yearBuilt: 'Built in {0}',
      yearCompletion: 'Est. completion {0}',
      nResidences: '{0} Residences',
      mlsId: '#MLS {0}',
      hasPhotos: 'PFS Realty Group has {1} photos of this {0}',
      hasPhotos_female: 'PFS Realty Group has {1} photos of this {0}',
      counties: {
        longTitle: 'Counties in Florida - PFS Realty Group',
        title: 'Counties in Florida',
        description:
          'Find homes and condos for sale and rent in counties of Florida.'
      },
      cities: {
        longTitle: 'Cities in {0} - PFS Realty Group',
        title: 'Cities in {0}',
        description: 'Find homes and condos for sale and rent in cities of {0}.'
      },
      neighborhoods: {
        longTitle: 'Neighborhoods in {0} - PFS Realty Group',
        title: 'Neighborhoods in {0}',
        description:
          'Find homes and condos for sale and rent in neighborhoods of {0}, Florida.'
      },
      calculator: {
        longTitle: 'Mortgage Calculator - PFS Realty Group',
        description:
          'Easily calculate your mortgage payments for properties in Florida with our online tool.'
      }
    }
  },
  es: {
    languages: {
      us: 'Inglés (Estados Unidos)',
      es: 'Español (Estados Unidos)',
      mx: 'Español (México)',
      co: 'Español (Colombia)',
      cl: 'Español (Chile)',
      pe: 'Español (Perú)',
      ec: 'Español (Ecuador)'
    },
    routes: {
      properties: 'casas-y-apartamentos',
      projects: 'nuevos-proyectos',
      blog: 'blog',
      blogSearch: 'busqueda',
      category: 'c',
      categories: {
        present: 'actualidad',
        qualityOfLife: 'calidad-de-vida',
        investment: 'inversion',
        miamiTourism: 'turismo-en-miami'
      },
      institutional: 'pfs',
      calculator: 'calculadora-de-hipotecas',
      marketReport: 'reporte-de-mercado',
      county: 'condado',
      account: 'cuenta',
      profile: 'perfil',
      savedProperties: 'propiedades-guardadas',
      savedSearches: 'busquedas-guardadas',
      notifications: 'notificaciones',
      resetPassword: 'reiniciar-contrasena',
      elite: 'elite',
      test: 'pagina-de-prueba',
    },
    header: {
      signUp: 'Sign Up'
    },
    contactForm: {
      title: 'Invierte en Miami',
      description:
        'Déjanos tus datos y recibe asesoría personalizada de cómo invertir en\n      Miami'
    },
    menu: {
      home: 'Inicio',
      search: 'Buscar',
      developments: 'Desarrollos',
      services: 'Servicios',
      aboutUs: 'Quiénes Somos',
      blog: 'Blog',
      resources: 'Recursos',
      contactUs: 'Contáctanos',
      newProperties: 'Propiedades Nuevas',
      properties: 'Propiedades Usadas',
      buildings: 'Búsqueda en Edificios',
      zone: 'Búsqueda por Zona',
      inListing: 'Listado PFS',
      financing: 'Financiamiento',
      financingLink: '/pfs/prestamos-para-extranjeros',
      propertyManagement: 'Admin. de Propiedades',
      propertyManagementLink: '/pfs/administracion-de-propiedades',
      accounting: 'Contabilidad, seguros e inmigración',
      accountingLink: '/pfs/asesoria-contable-y-legal',
      insurance: 'Seguro',
      migration: 'Inmigración',
      rentInDollars: 'Renta en Dólares',
      rentInDollarsLink: '/pfs/renta-en-dolares',
      aboutUsLink: '/pfs/quienes-somos',
      pfs: 'PFS',
      institutional: 'Institucional',
      contactUsLink: '/pfs/contactanos'
    },
    common: {
      translatedVersion: 'Versión en inglés',
      searchResults: 'Resultados de Búsqueda',
      date: 'Fecha',
      mightInterestYou: 'Quizás pueda interesarte:',
      bestHousesForSale: 'Mejores casas en venta',
      cookiesMessage:
        'Usamos cookies para asegurar que te damos la mejor experiencia en nuestra web. Si continúas usando este sitio, asumiremos que estás de acuerdo con ello.',
      closeCookiesMessage: 'Cerrar mensaje de cookies',
      contactMenu: 'Contacto',
      accountMenu: 'Cuenta',
      signInSuccess: 'Sesión iniciada correctamente',
      signUpSuccess: 'Cuenta creada correctamente',
      accept: 'Aceptar',
      select: 'Seleccionar',
      on: 'Encendido',
      off: 'Apagado',
      done: 'Listo',
      goBack: 'Volver',
      goBackHome: ' Volver al Inicio',
      delete: 'Eliminar',
      menu: 'Menú',
      autocomplete: 'Auto completar',
      close: 'Cerrar',
      closePopup: 'Cerrar ventana emergente',
      closeFilters: 'Cerrar filtros',
      noResults: 'No hay resultados',
      noOptionsAvailable: 'No hay opciones disponibles',
      range: '{0} a {1}',
      by: 'por {0}',
      fromN: 'Desde {0}',
      toN: 'Hasta {0}',
      photoOf: 'Foto de {0}',
      errors: {
        unknown: 'Error'
      },
      viewMore: 'Ver Más',
      viewLess: 'Ver Menos',
      oops: '¡Ups!',
      noItemsFound: 'No se encontraron resultados',
      pageNotFound: 'No hemos podido encontrar la página que buscas',
      tryAdjustingFilters: 'Intenta ajustar tu búsqueda',
      next: 'Siguiente',
      previous: 'Anterior',
      perMonthNumber: '/mes',
      link: 'Enlace',
      yes: 'Sí',
      no: 'No',
      avgPrice: 'Precio promedio',
      avgPriceSqft: 'Precio promedio / SQFT',
      infoDisclaimer:
        'La información de IDX se proporciona exclusivamente para uso personal y no comercial, y no se puede utilizar para ningún otro propósito que no sea identificar propiedades potenciales que los consumidores puedan estar interesados en comprar. La información se considera confiable pero no está garantizada. \nLa oferta de compensación del corredor de la lista se realiza solo a los participantes de la MLS donde se archiva la lista.'
    },
    elite: {
      menu: {
        launches: 'Lanzamientos',
        calendar: 'Agenda Miami'
      },
      auth: {
        login: 'Ingresar',
        profile: 'Usuario'
      },
      title: 'Elite Club - PFS Realty',
      description:
        'Conozca los beneficios de ser parte del Elite Club de PFS Realty',
      intro: `Invertir en Miami, es una excelente decision, haberlo hecho con <strong>PFS Realty Group</strong> es abrir las puertas de grandes oportunidades y beneficios.`,
      welcome: 'Bienvenido a PFS Élite Club',
      benefits:
        'Conozca algunos de sus <span class="font-bodoni">beneficios</span>',
      eventCalendar: '<span class="font-bodoni">Calendario</span> de eventos',
      footer: 'Copyright© 2024 PFS Realty Group. Todos los derechos reservados.'
    },
    blog: {
      title: 'Oportunidades en Miami y el Sur de la Florida',
      description:
        'Oportunidades en Miami y en el Sur de La Florida Conoce las oportunidades de inversión, para vivir o disfrutar en Miami y el Sur de La Florida.',
      subtitle:
        'Conoce las oportunidades de inversión, para vivir o disfrutar en Miami y el Sur de la Florida',
      contentTable: 'Tabla de contenidos',
      nextPost: 'Siguiente',
      previousPost: 'Anterior',
      category: 'Categoría',
      categories: {
        present: 'Actualidad',
        qualityOfLife: 'Calidad de Vida',
        investment: 'Inversión',
        miamiTourism: 'Turismo en Miami'
      },
      categoryArticles: 'Artículos de la categoría',
      searchResults: 'Resultados de la búsqueda',
      searchHint: 'Afina la búsqueda para ver resultados en el blog',
      relatedPosts: 'Noticias relacionadas',
      seo: {
        title: 'Blog en Español | PFS Realty'
      }
    },
    auth: {
      title: 'Bienvenido a PFS',
      goToProfile: 'Ir a mi perfil',
      signIn: 'Ingresar',
      signOut: 'Cerrar sesión',
      signUp: 'Nueva cuenta',
      forgotPassword: 'Recuperar contraseña',
      forgotPasswordHelp:
        'Ingresa tu dirección de correo y le enviaremos un enlace para cambiar su contraseña.',
      forgotPasswordSubmit: 'Enviar',
      forgotPasswordQuestion: '¿Olvidaste tu contraseña?',
      knowPasswordQuestion: '¿Sabe su contraseña?',
      forgotPasswordSuccess:
        'Se ha enviado un correo electrónico con instrucciones para cambiar su contraseña.',
      passwordReset: 'Cambiar contraseña',
      passwordResetSuccess: 'Tu contraseña ha sido cambiada correctamente.',
      passwordResetHelp: 'Ingresa tu nueva contraseña.',
      email: 'Correo electrónico',
      emailNewAccount: 'Ingresa un correo electrónico',
      emailPlaceholder: 'Ingresa un correo electrónico',
      emailRequired: 'Ingresa tu correo electrónico',
      emailInvalid: 'Ingresa un correo electrónico válido',
      password: 'Contraseña',
      passwordNewAccount: 'Crea una contraseña',
      passwordPlaceholder: 'Ingresa tu contraseña',
      createPasswordPlaceholder: 'Crear una contraseña',
      passwordRequired: 'Ingresa una contraseña',
      passwordInvalid: 'Ingresa una contraseña válida',
      loginToSavePropertyHint:
        'Inicia sesión o crea una cuenta para guardar una propiedad',
      signInErrors: {
        '400': 'Revise su correo electrónico y/o contraseña',
        '401': 'Correo y/o contraseña inválidos',
        '403': 'Debe verificar su correo electrónico',
        '500': 'Error del servidor',
        unknown: 'Error'
      },
      signUpErrors: {
        '400': 'Revise su correo electrónico y/o contraseña',
        '403': 'Correo y/o contraseña inválidos',
        '409': 'Correo ya registrado',
        '500': 'Error del servidor',
        unknown: 'Error'
      },
      resetPasswordErrors: {
        '10': 'Correo electrónico inválido',
        '40': 'Usuario no encontrado',
        '400': 'Usuario no encontrado',
        '403': 'Correo electrónico inválido',
        '500': 'Error del servidor',
        unknown: 'Error'
      },
      setNewPasswordErrors: {
        '10': 'Correo electrónico inválido',
        '40': 'Usuario no encontrado',
        '400': 'Usuario no encontrado',
        '403': 'Correo electrónico inválido',
        '500': 'Error del servidor',
        unknown: 'Error'
      },
      submitSignIn: 'Ingresar',
      submitSignUp: 'Enviar',
      charactersAmountHint: 'Al menos 8 caracteres',
      characterMixHint: 'Mezcla de letras y números',
      specialCharacterHint: 'Al menos un carácter especial',
      signInDisclaimer:
        'Al presionar Enviar, aceptas los {0}términos y condiciones{1}.',
      orConnectWith: 'O conéctate con'
    },
    home: {
      seo: {
        title:
          "Inmobiliaria en Miami Florida {'|'} Bienes Raices - PFS Realty Group",
        description:
          'Asesoramos a nuestros clientes con la compra y venta de inmuebles en Miami Florida. Somos expertos en bienes raices en Miami y Miami Beach.'
      },
      hero: {
        title: 'Casas y Apartamentos en Venta y Renta en Miami',
        p: 'Propiedades',
        f: 'Financiamiento',
        s: 'Servicios'
      },
      used: 'Usado',
      new: 'Nuevo',
      rent: 'Rentar',
      buy: 'Usado',
      location: 'Ciudad, código postal o condado',
      currentLocation: 'Ubicación actual',
      contactFormTitle:
        '¡Contacta a uno de nuestros asesores para más información!',
      contactFormText:
        'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
      properties_title: 'Nuestro portafolio de propiedades',
      propertiesPortfolio: 'Apartamentos y Casas en venta y renta en Miami',
      apartmentsPortfolioDescription: 'Nuestro portafolio de apartamentos',
      housesPortfolioDescription: 'Nuestro portafolio de casas',
      find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
      find_property_text:
        'Tenemos un amplio portafolio de propiedades perfectas para ti.',
      clients: {
        title:
          '2000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
        features: {
          one: {
            title: '22 AÑOS DE EXPERIENCIA EN VENTAS DE PROPIEDADES EN EE. UU.',
            description:
              'Más de dos décadas de experiencia en ventas de propiedades en EE. UU., brindando un servicio confiable y resultados exitosos.'
          },
          two: {
            title: '65 ASESORES EN 12 PAÍSES DE AMÉRICA Y EUROPA.',
            description:
              'Amplia red de 65 asesores estratégicamente ubicados en América y Europa para maximizar tus oportunidades de inversión inmobiliaria.'
          },
          three: {
            title: 'MÁS DE 2000 CLIENTES SATISFECHOS CONFÍAN EN NOSOTROS.',
            description:
              'Más de 2000 clientes satisfechos respaldan nuestro compromiso con un servicio excepcional y resultados satisfactorios en inversiones inmobiliarias. Únete y experimenta el éxito con PFS Realty.'
          }
        }
      },
      services: {
        title: 'SERVICIOS INTEGRALES',
        properties: {
          title: 'Adquiere la propiedad ideal',
          description:
            'Compra de inmuebles nuevos y usados de alta calidad. Contamos con amplia experiencia y conocimiento para encontrar la propiedad ideal.'
        },
        financing: {
          title: 'Financiamiento a tu medida',
          description:
            'Ofrecemos créditos hipotecarios flexibles y personalizados para extranjeros con mínimo 30% de entrada. Adaptamos las condiciones a tus necesidades.'
        },
        extra_income: {
          title: 'Genera ingresos extras alquilando',
          description:
            'Alquiler de tu propiedad con inquilinos que generan ingresos en dólares. Gestionamos integralmente para maximizar la rentabilidad.'
        },
        management: {
          title: 'Administración de propiedades',
          description:
            'Cuidado profesional, mantenimiento preventivo y correctivo, administración eficiente de tu inversión inmobiliaria. Optimizamos costos y beneficios.'
        },
        legal: {
          title: 'Asesoría legal y contable integral',
          description:
            'Equipo interdisciplinario de profesionales altamente especializados en todos los aspectos legales y contables. Aseguramos el cumplimiento normativo.'
        }
      },
      events: {
        title: 'Próximos eventos',
        formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
        registerButton: 'Reserva Entrada'
      },
      testimonialsTitle: 'Testimoniales',
      news_title: 'Nuestro Blog',
      popupFormTitle:
        'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
      popupFormMessage:
        'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
    },
    account: {
      accountSettings: 'Configuración de cuenta',
      profile: 'Perfil',
      profileHint:
        'Personalice su cuenta y actualice sus preferencias de ingreso',
      notifications: 'Notificaciones',
      notificationsSectionHint:
        'Administre sus notificaciones y preferencias de correo electrónico',
      notificationsHint: 'Encender o apagar las notificaciones de su cuenta',
      searchNotificationsHint: 'Encender las notificaciones para esta búsqueda',
      enableAllNotifications: 'Habilitar todas las notificaciones',
      propertyNotificationsEnabled:
        'Notificaciones habilitadas para esta propiedad',
      propertyNotificationsDisabled:
        'Notificaciones deshabilitadas para esta propiedad',
      savedProperties: 'Propiedades Salvadas',
      savedPropertiesHint:
        'Mantenga un registro de las casas que le gustan y las que ha visto recientemente.',
      toggleNotificationsHint:
        'Use el interruptor para encender o apagar las notificaciones',
      savedSearches: 'Búsquedas Salvadas',
      recentlyViewedProperties: 'Vistas Recientemente',
      personalInfo: 'Información personal',
      name: 'Nombre',
      namePlaceholder: 'Ingresa tu nombre',
      lastName: 'Apellido',
      lastNamePlaceholder: 'Ingresa tu apellido',
      picture: 'Foto',
      pictureHint: 'Suba una foto de perfil',
      accessAndSecurity: 'Acceso y seguridad',
      email: 'Correo electrónico',
      emailPlaceholder: 'Ingresa tu correo electrónico',
      password: 'Contraseña',
      passwordPlaceholder: 'Ingresa tu contraseña',
      noSavedProperties: 'Obten actualizaciones de tus propiedades salvadas',
      searchProperties: 'Buscar casas',
      untitledSavedSearch: 'Búsqueda sin título',
      noSavedSearches: 'Obten actualizaciones de tus busquedas salvadas',
      savedSearchesHint:
        'Salvar tus busquedas te ahorra tiempo y te dejara saber de las nuevas actualizaciones.',
      changePasswordEmailWithButton:
        'Cambie su contraseña a través de su correo electrónico {button}.',
      changePasswordEmailButton: 'haciendo clic aquí',
      changePasswordEmailSent:
        'Se ha enviado un correo electrónico a {email} con instrucciones para cambiar su contraseña.'
    },
    search: {
      previous: 'Anterior',
      next: 'Proximo',
      title: 'Búsqueda de casas, apartamentos y otros inmuebles',
      searchPlaceholder:
        'Ciudad, condado, código postal, vecindario o nuevo proyecto',
      searchLabel: 'Buscar',
      nResults: '{0} resultados',
      nActiveProperties: '{0} activos',
      resetFilters: 'Borrar filtros',
      resetFiltersLong: 'Remover todos los filtros',
      acceptFilters: 'Aceptar',
      saveSearch: 'Salvar búsqueda',
      searchSaved: 'Búsqueda salvada',
      map: 'Mapa',
      listView: 'Lista',
      county: 'Condado',
      city: 'Ciudad',
      zipcode: 'Código postal',
      neighborhood: 'Vecindario',
      allCounties: 'Todos los condados',
      allCities: 'Todas las ciudades',
      allZipcodes: 'Todos los códigos postales',
      allNeighborhoods: 'Todos los vecindarios',
      from: 'Desde',
      to: 'Hasta',
      noMin: 'Sin min.',
      noMax: 'Sin max.',
      bedroomsCard: 'habitación | habitaciones',
      bathsFullCard: 'baño | baños',
      bedroomsShort: 'hab.',
      bathsFullShort: 'ba.',
      areaShort: 'sqft',
      areaOf: 'área de',
      postalCodeShort: 'Cod. Postal {0}',
      filters: 'Filtros',
      propertyType: 'Tipo de inmueble',
      propertyTypes: 'Tipos de propiedad',
      selectPropertyType: 'Propiedades',
      selectHouseType: 'Tipos',
      selectCondoType: 'Tipos',
      selectPropertySubTypes: 'Tipos',
      property: 'Propiedad',
      properties: 'Casas y apartamentos',
      projects: 'Proyectos',
      houseType: 'Casa',
      housesType: 'Casas',
      condoType: 'Apartamento',
      condosType: 'Apartamentos',
      house: 'Casa',
      houses: 'Casas',
      singleFamilyResidence: 'Single Family',
      singleFamilyResidences: 'Single Families',
      townhouse: 'Townhouse',
      townhouses: 'Townhouses',
      villa: 'Villa',
      villas: 'Villas',
      condominium: 'Condo',
      condominiums: 'Condos',
      apartment: 'Apartamento',
      apartments: 'Apartamentos',
      multiFamily: 'Multi Family',
      multiFamilies: 'Multi Families',
      building: 'Edificio',
      buildings: 'En Edificios',
      price: 'Precio',
      priceFrom: 'Precio desde',
      priceTo: 'Precio hasta',
      priceFromShort: 'Precio min.',
      priceToShort: 'Precio max.',
      areas: 'áreas',
      bedrooms: 'Habitaciones',
      nOrMoreBedrooms: '{0} o más habitaciones',
      nOrLessBedrooms: 'hasta {0} habitaciones',
      nBedrooms: '{0} habitaciones',
      nRooms: '{0} cuartos',
      moreOptions: 'Más opciones',
      showPlaces: 'Mostrar lugares',
      bedroomsAndBathrooms: 'Hab. y baños',
      bathrooms: 'Baños',
      nOrMoreBathrooms: '{0} o más baños',
      nOrLessBathrooms: 'hasta {0} baños',
      nBathrooms: '{0} baños',
      nArea: '{0} pies cuadrados',
      nAreaShort: '{0}',
      nAreaM2: '{0} m²',
      nYear: 'el {0}',
      area: 'Área',
      areaFrom: 'Área desde',
      areaTo: 'Área hasta',
      year: 'Año',
      yearFrom: 'Año desde',
      yearTo: 'Año hasta',
      furnished: 'Amoblado',
      view: 'Vista',
      viewOptions: {
        any: 'Cualquiera',
        ocean: 'Al agua'
      },
      condoCost: 'Costo del condominio',
      condoCostFrom: 'Costo del condominio desde',
      condoCostTo: 'Costo del condominio hasta',
      hopa: 'Comunidad de +55',
      dock: 'Muelle',
      shortTermLease: 'Renta corta',
      inListing: 'En el listado de PFS',
      inListingItems: '{0} en el listado de PFS',
      pendingAndUnderContract: 'Pendientes y Bajo contrato',
      pendingAndUnderContractProperties:
        'Propiedades pendientes y bajo contrato',
      advancedSearch: 'Búsqueda avanzada',
      viewMap: 'Ver mapa',
      viewSatellite: 'Ver satélite',
      viewBuildings: 'Ver edificios',
      view2D: '2D',
      view3D: '3D',
      sortBy: 'Ordenar',
      visitSite: 'Ver sitio web',
      mapLayers: {
        newProjects: 'Nuevos proyectos',
        beaches: 'Playas',
        malls: 'Centros comerciales',
        restaurants: 'Restaurantes',
        collegeUniversities: 'Universidades',
        publicSchools: 'Escuelas públicas',
        privateSchools: 'Escuelas privadas',
        hospitals: 'Hospitales'
      },
      sortByOptions: {
        mostRecent: 'Más reciente',
        oldest: 'Más antigua',
        highestPrice: 'Mayor precio',
        lowestPrice: 'Menor precio',
        fewestBathrooms: 'Menor cant. baños',
        mostBathrooms: 'Mayor cant. baños',
        fewestBedrooms: 'Menor cant. habitaciones',
        mostBedrooms: 'Mayor cant. habitaciones',
        smallestArea: 'Menor área',
        largestArea: 'Mayor área',
        firstToFinish: 'Primeros en finalizar',
        lastToFinish: 'Últimos en finalizar'
      },
      buyTypeOptions: {
        used: 'Usado | Usados',
        new: 'Nuevo | Nuevos',
        rent: 'Rentar',
        buy: 'Comprar',
        sold: 'Vendido | Vendidos',
        rented: 'Rentado | Rentados'
      },
      hopaOptions: {
        any: 'Cualquiera',
        'hopa-eligible': 'Apto para HOPA',
        'no-hopa': 'Sin HOPA'
      },
      availableItems: '{items} a la venta y renta',
      soldItems: '{items} vendidos',
      soldItems_female: '{items} vendidas',
      rentedItems: '{items} rentados',
      rentedItems_female: '{items} rentadas',
      soldOrRentedItems: '{items} vendidos o rentados',
      soldOrRentedItems_female: '{items} vendidas o rentadas',
      newItems: '{items} nuevos en venta',
      newItems_female: '{items} nuevas en venta',
      usedItems: '{items} usados en venta',
      usedItems_female: '{items} usadas en venta',
      itemsForSale: '{items} en venta',
      itemsForRent: '{items} en renta',
      rentSearchResults: '{items} a la renta en {search}',
      buySearchResults: '{items} a la venta en {search}',
      soldSearchResults: '{items} vendidas en {search}',
      rentedSearchResults: '{items} rentadas en {search}',
      buyRentSearchResults: '{items} a la venta y renta en {search}',
      rentSearchResultsNearYou: '{0} a la renta cerca de tu ubicación',
      buySearchResultsNearYou: '{0} a la venta cerca de tu ubicación',
      soldSearchResultsNearYou: '{0} vendidas cerca de tu ubicación',
      rentedSearchResultsNearYou: '{0} rentadas cerca de tu ubicación',
      buyRentSearchResultsNearYou:
        '{0} a la venta y renta cerca de tu ubicación',
      rentResults: 'Rentas',
      soldResults: 'Propiedades vendidas',
      rentedResults: 'Propiedades rentadas',
      buyResults: 'Propiedades en venta',
      anyResults: 'Propiedades disponibles',
      all: 'Todos',
      otherItems: 'Propiedades cercanas',
      otherSearches: 'Otras personas también buscaron',
      removeBoundary: 'Quitar límites',
      addBoundary: 'Añadir límite',
      cancelBoundaryDraw: 'Cancelar',
      otherCities: 'Otras ciudades',
      sortByCity: 'Ciudades'
    },
    paramsKeys: {
      propertyType: 'pt',
      propertySubType: 'ps',
      projectType: 'py',
      price: 'pr',
      bedrooms: 'h',
      bathsFull: 'b',
      area: 'a',
      year: 'y',
      furnished: 'am',
      view: 'v',
      condoCost: 'cc',
      dock: 'd',
      shortTermLease: 'rc',
      excludePendingAndUnderContract: 'epc',
      inListing: 'el',
      sortBy: 'o',
      buyType: 'bt',
      page: 'pg',
      location: 'l',
      county: 'co',
      city: 'ci',
      zipcode: 'cp',
      neighborhood: 'n',
      geolocation: 'g',
      usedProject: 'up',
      hopa: 'ho'
    },
    legacyParamsKeys: {
      propertyType: 'tipo',
      propertySubType: 'propiedades',
      projectType: 'proyectos',
      price: 'precio',
      bedrooms: 'habitaciones',
      bathsFull: 'banos',
      area: 'area',
      year: 'fecha',
      furnished: 'amoblado',
      view: 'vista',
      condoCost: 'costo-condominio',
      dock: 'muelle',
      shortTermLease: 'renta-corta',
      excludePendingAndUnderContract: 'excluir-pendientes-y-bajo-contrato',
      inListing: 'en-listado',
      sortBy: 'orden',
      buyType: 'tipo',
      page: 'pagina',
      location: 'busqueda',
      county: 'condado',
      city: 'ciudad',
      zipcode: 'codigo-postal',
      neighborhood: 'vecindario',
      geolocation: 'gl',
      usedProject: 'usado',
      hopa: 'hopa'
    },
    paramsAliases: {
      RES: 'comprar',
      RNT: 'rentar',
      SOL: 'vendidos',
      SOL_alt: 'vendido',
      RTD: 'rentados',
      RTD_alt: 'rentado',
      house: 'casas',
      singleFamilyResidence: 'single-families',
      townhouse: 'townhouses',
      villa: 'villas',
      condo: 'condos',
      apartment: 'apartamentos',
      building: 'en-edificios',
      multiFamily: 'multi-families',
      'pre-construction': 'preconstruccion',
      'under-construction': 'en-construccion',
      built: 'construido',
      'on-the-market': 'en-el-mercado',
      'hopa-eligible': 'hopa',
      'no-hopa': 'no-hopa',
      'yearBuilt:desc': 'mas-nuevos',
      'yearBuilt:asc': 'mas-antiguos',
      'listPrice:desc': 'mayor-precio',
      'listPrice:asc': 'menor-precio',
      'bathsFull:desc': 'mas-banos',
      'bathsFull:asc': 'menos-banos',
      'bedrooms:desc': 'mas-habitaciones',
      'bedrooms:asc': 'menos-habitaciones',
      'propertyArea:desc': 'mayor-area',
      'propertyArea:asc': 'menor-area',
      'yearCompletion:desc': 'ultimos-en-finalizar',
      'yearCompletion:asc': 'primeros-en-finalizar',
      city: 'ciudades',
      availability: 'disponibilidad',
      pending: 'pendiente',
      closed: 'cerrado',
      statistics: 'estadisticas'
    },
    locationTypes: {
      county: 'Condado',
      city: 'Ciudad',
      zip_code: 'Código postal',
      project: 'Proyecto',
      building: 'Edificio',
      address: 'Dirección',
      neighborhood: 'Vecindario',
      mls_id: 'MLS ID'
    },
    property: {
      save: 'Salvar',
      saved: 'Salvado',
      propertySaved: 'Propiedad salvada en favoritos',
      propertyRemoved: 'Propiedad removida de favoritos',
      backToSearch: 'Volver a Búsqueda',
      backToProject: 'Volver al edificio',
      share: 'Compartir',
      copyShareLink: 'Copiar enlace para compartir',
      previousProperty: 'Anterior',
      nextProperty: 'Siguiente',
      bedrooms: 'Habitaciones',
      bathrooms: 'Baños',
      factsAndFeatures: 'Características',
      interior: 'Interior',
      bedroomsAndBathrooms: 'Dormitorios y Baños',
      bedroomsLong: 'Dormitorios',
      bathroomsLong: 'Baños',
      fullBathrooms: 'Baños completos',
      flooring: 'Pisos',
      heating: 'Calefacción',
      heatingFeatures: 'Características de calefacción',
      cooling: 'Enfriamiento',
      hasCooling: 'Tiene enfriamiento',
      coolingFeatures: 'Características de enfriamiento',
      appliances: 'Electrodomésticos',
      appliancesIncluded: 'Electrodomésticos incluidos',
      interiorFeatures: 'Características interiores',
      otherInteriorFeatures: 'Otras características interiores',
      virtualTour: 'Tour virtual',
      property: 'Propiedad',
      parking: 'Estacionamiento',
      parkingFeatures: 'Características de estacionamiento',
      poolFeatures: 'Características de la piscina',
      privatePool: 'Piscina privada',
      exteriorFeatures: 'Características exteriores',
      patioAndPorchFeatures: 'Detalles del patio y el porche',
      viewDescription: 'Descripción de la vista',
      otherPropertyInformation: 'Otra información de la propiedad',
      parcelNumber: 'Número de parcela',
      attachedToAnotherStructure: 'Adjunto a otra estructura',
      otherEquipment: 'Otros equipos',
      construction: 'Construcción',
      typeAndStyle: 'Tipo y estilo',
      homeType: 'Tipo de hogar',
      materialInformation: 'Información del material',
      constructionMaterials: 'Materiales de construcción',
      condition: 'Condición',
      propertyCondition: 'Condición de la propiedad',
      newConstruction: 'Construcción nueva',
      type: 'Tipo',
      yearBuilt: 'Año de construcción',
      communityAndNeighborhood: 'Comunidad y vecindario',
      security: 'Seguridad',
      location: 'Ubicación',
      region: 'Región',
      neighborhood: 'Vecindario',
      hoaAndFinancial: 'HOA y financiamiento',
      hoa: 'HOA',
      hasHoa: 'Tiene HOA',
      hoaFee: 'Cuota de HOA',
      amenitiesIncluded: 'Servicios incluidos',
      otherFinancialInformation: 'Otra información financiera',
      buyerAgencyCompensation: 'Compensación de la agencia del comprador',
      contactAgent: 'Contactar agente',
      requestShowing: 'Solicitar visita',
      similarProperties: 'Propiedades relacionadas',
      shareTitle: 'Propiedad en PFS',
      shareText: 'Mira esta propiedad en PFS',
      shareLinkCopied: 'Enlace para compartir copiado al portapapeles',
      areaSqft: 'Área (sqft.)',
      mlsCode: 'MLS #',
      sold: 'Vendido',
      sold_female: 'Vendida',
      rented: 'Rentado',
      rented_female: 'Rentada',
      active: 'Activo',
      active_female: 'Activa',
      estimatedPayment: 'Pago estimado',
      getPrequalification: 'Calcula tus pagos mensuales'
    },
    project: {
      newProjects: 'Nuevos proyectos',
      usedBuildings: 'Edificios usados',
      project: 'Proyecto',
      priceSQFT: 'Precio por pie cuadrado',
      priceSQFTShort: '$/Pie²',
      SQFT: 'Área',
      SQFTShort: 'Pies²',
      preConstruction: 'Preconstrucción',
      underConstruction: 'En construcción',
      built: 'Construido',
      onTheMarket: 'En el mercado',
      profile: 'Perfil',
      availability: 'Disponibilidad',
      pending: 'Pendiente',
      closed: 'Cerrado',
      stats: 'Estadísticas',
      projectDetails: 'Detalles',
      propertyDetails: 'Detalles de la propiedad',
      floorPlans: 'Planos de planta',
      features: 'Características',
      amenities: 'Amenidades',
      appliance: 'Electrodomesticos',
      heating: 'Calefacción',
      maintenanceFee: 'Cuota de mantenimiento',
      construction: 'Construcción',
      garage: 'Garage',
      restrictions: 'Restricciones',
      exterior: 'Exterior',
      parking: 'Estacionamiento',
      termsConsidered: 'Términos considerados',
      interior: 'Interior',
      petsAllowed: 'Mascotas permitidas',
      flooring: 'Pisos',
      furnished: 'Amoblado',
      HOAFee: 'Cuota de mantenimiento/cuarto',
      HOPA: 'HOPA',
      hopaEligible: 'Apto',
      noHopa: 'Sin HOPA',
      cooling: 'Aire acondicionado',
      yearBuilt: 'Año de construcción',
      similarProjects: 'Proyectos relacionados',
      yearCompletionShort: 'Est. completado',
      stories: 'Pisos',
      residences: 'Residencias',
      shareTitle: 'Proyecto en PFS',
      shareText: 'Mira este proyecto en PFS',
      shareLinkCopied: 'Enlace para compartir copiado al portapapeles',
      areaSqft: 'Área (sqft.)',
      sales: 'Ventas',
      rentals: 'Rentas',
      unitNumberShort: '# unidad',
      listPrice: 'Precio de lista',
      maintenanceExpenseShort: 'Mantenimiento ($)',
      listDate: 'Fecha de lista',
      availableCondosForRent: 'Apartamentos disponibles para rentar',
      pendingCondosForRent: 'Apartamentos pendientes para rentar',
      closedCondosForRent: 'Apartamentos rentados',
      availableCondosForSale: 'Apartamentos disponibles para la venta',
      pendingCondosForSale: 'Apartamentos pendientes para la venta',
      closedCondosForSale: 'Apartamentos vendidos',
      noCondos:
        '{project} no tiene condominios disponibles con las opciones seleccionadas',
      condos:
        '{project} tiene {n} apartamento(s) con las opciones seleccionadas con un precio por pie cuadrado promedio de {price}',
      statsLabels: {
        averageListPricePerSQFT: 'Precio promedio de lista / SQFT',
        minListPricePerSQFT: 'Precio mínimo de lista / SQFT',
        maxListPricePerSQFT: 'Precio máximo de lista / SQFT',
        monthsOfInventory: 'Meses de inventario',
        unitsSoldPast3Months: 'Unidades vendidas en los últimos 3 meses',
        unitsSoldPast6Months: 'Unidades vendidas en los últimos 6 meses',
        unitsSoldPast12Months: 'Unidades vendidas en los últimos 12 meses',
        unitsSoldPast24Months: 'Unidades vendidas en los últimos 24 meses',
        availableUnitsForSale: 'Unidades disponibles para la venta',
        TotalUnitsAvailableForSaleP:
          '% de unidades totales disponibles para la venta',
        minSalesPricePerSQFT_6Months: 'Precio mínimo de venta / SQFT (6 meses)',
        maxSalesPricePerSQFT_6Months: 'Precio máximo de venta / SQFT (6 meses)',
        averageSalesPricePerSQFT_3Months:
          'Precio promedio de venta / SQFT (3 meses)',
        averageSalesPricePerSQFT_6Months:
          'Precio promedio de venta / SQFT (6 meses)',
        averageSalesPricePerSQFT_12Months:
          'Precio promedio de venta / SQFT (12 meses)',
        averageSalesPricePerSQFT_24Months:
          'Precio promedio de venta / SQFT (24 meses)',
        availableUnitsForRent: 'Unidades disponibles para alquilar',
        totalUnitsAvailableForRentP:
          '% de unidades totales disponibles para alquilar',
        unitsRentedPast3Months: 'Unidades alquiladas en los últimos 3 meses',
        unitsRentedPast6Months: 'Unidades alquiladas en los últimos 6 meses',
        unitsRentedPast12Months: 'Unidades alquiladas en los últimos 12 meses',
        unitsRentedPast24Months: 'Unidades alquiladas en los últimos 24 meses',
        avgSalesPriceSqft: 'Precio promedio de venta / SQFT',
        avgRentalsPriceSqft: 'Precio promedio de alquiler / SQFT',
        year: 'Año',
        soldUnits: 'Unidades vendidas',
        rentedUnits: 'Unidades alquiladas',
        nSoldUnits: '{0} unidades vendidas',
        informationReliability:
          'Información considerada confiable pero no garantizada.'
      }
    },
    calculator: {
      title: 'Calculadora de Hipotecas',
      monthlyPayments: 'Pagos Mensuales',
      principalInterest: 'Principal e Intereses',
      propertyTax: 'Impuesto Predial',
      associationFee: 'Cuota de Asociación',
      insuranceFee: 'Cuota de Seguro',
      homePrice: 'Precio',
      propertyTaxYearly: 'Impuesto Predial (anual)',
      associationFeeMonthly: 'Cuota de Asociación (mensual)',
      insuranceFeeMonthly: 'Cuota de Seguro (mensual)',
      downPayment: 'Pago Inicial (%)',
      interestRate: 'Tasa de Interés (%)',
      interestRatePlaceholder: 'Tasa de Interés',
      loanType: 'Tipo de Préstamo',
      loanType10Year: 'Fijo a 10 Años',
      loanType15Year: 'Fijo a 15 Años',
      loanType20Year: 'Fijo a 20 Años',
      loanType30Year: 'Fijo a 30 Años',
      disclaimer: '*Los cálculos son un estimado.'
    },
    footer: {
      city_label: 'Ciudad',
      zip_code_label: 'Código postal',
      county_label: 'Condado',
      pre_construction_label: 'Preconstrucción',
      home_type_label: 'Tipo de propiedad',
      price_label: 'Precio de Venta',
      rent_label: 'Precio de Renta',
      other_cities: 'Otras ciudades',
      other_zipcodes: 'Otros códigos',
      other_types: 'Otros tipos',
      other_counties: 'Otros condados',
      other_preconstructions: 'Otras preconstrucciones',
      other_prices: 'Otros precios',
      findUs: 'Encuéntranos:',
      salesTitle: 'Representantes en:',
      countries:
        'MÉXICO • COLOMBIA • ARGENTINA • ESPAÑA • VENEZUELA • ECUADOR • CHILE\n            • PERÚ • CANADÁ • PORTUGAL',
      interest: 'De interés',
      termsAndConditions: 'Términos y condiciones',
      termsAndConditionsLink: '/pfs/terminos-y-condiciones',
      whoWeAre: 'Quiénes somos',
      allRightsReserved: 'Todos los derechos reservados'
    },
    connectors: {
      in: '{0} en {1}',
      with: '{0} con {1}',
      without: '{0} sin {1}',
      from: '{0} desde {1}',
      to: '{0} hasta {1}',
      and: '{0} y {1}',
      or: '{0} o {1}',
      of: '{0} de {1}',
      ranging: '{0} {1}',
      ofType: '{0} de tipo {1}',
      ofPrice: '{0} de {1}',
      type: '{0} tipo {1}',
      builtIn: '{0} construido en {1}',
      builtIn_female: '{0} construida en {1}',
      default: '{0} {1}',
      withView: '{0} con vista {1}',
      withViewWith: '{0} con vista con {1}',
      withViewTo: '{0} con vista a {1}',
      withViewToThe: '{0} con vista al {1}',
      withViewToThe_female: '{0} con vista a la {1}',
      withViewToThe_female_plural: '{0} con vista a las {1}',
      recently: '{0} recientemente'
    },
    institutional: {
      seoTitle: 'PFS Realty Group',
      title: 'Oportunidades en Miami y en el Sur de La Florida',
      description:
        'Conoce las oportunidades de inversión, para vivir o disfrutar en Miami y el Sur de La Florida.'
    },
    marketReport: {
      title: 'Reporte de Mercado',
      description:
        'Conoce el estado actual del mercado inmobiliario en Miami y el Sur de La Florida.',
      longTitle:
        'Reporte del Mercado Inmobiliario en el Sur de La Florida - PFS Realty Group',
      help: 'Seleccione la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada',
      activeListings: 'Listados activos',
      nActiveListingsIn: '{count} Propiedades Activas en {location}',
      nActiveListings: '{0} Propiedades Activas en todos los condados',
      recentlySold: 'Recientemente vendidos',
      recentlyRented: 'Recientemente rentados',
      recentlyClosed: 'Recientemente cerrados',
      priceTrends: 'Tendencias de precios',
      averagePrice: 'Precios promedio en {0}',
      buyTypes: {
        sales: 'Ventas',
        rentals: 'Rentas'
      }
    },
    pages: {
      neighborhoodsIn: 'Vecindarios en {0}',
      citiesIn: 'Ciudades en {0}',
      counties: 'Condados'
    },
    seo: {
      locale: 'mx',
      alternateLocale: 'us',
      defaultTitle: 'PFS Realty Group',
      defaultDescription:
        'En PFS Realty encuentra bienes raices e inmuebles en venta y renta en la Florida.',
      home: 'Inicio',
      defaultSingle: 'Casa o apartamento',
      singleSale: '{0} en venta',
      singleRent: '{0} en renta',
      singleSaleAlt: '{0} a la venta',
      singleRentAlt: '{0} a la renta',
      defaultMultiple: 'Casas y apartamentos',
      multipleSale: 'Casas y apartamentos en venta',
      multipleRent: 'Casas y apartamentos en renta',
      project: 'Proyecto',
      projects: 'Proyectos',
      preConstruction: '{0} en preconstrucción',
      underConstruction: '{0} en construcción',
      built: '{0} recientes',
      onTheMarket: '{0} en el mercado',
      complementaryTitle:
        'en PFSRealty encuentra bienes raices e inmuebles en venta y renta.',
      commplementaryDescription:
        'en PFSRealty encuentra bienes raices, inmuebles, casas y apartamentos en venta y renta.',
      atFlorida: 'en la Florida',
      numberArticle: 'los {0}',
      numberArticleSingle: '{0}',
      yearBuilt: 'Construido en {0}',
      yearCompletion: 'Est. completado {0}',
      nResidences: '{0} residencias',
      mlsId: '#MLS {0}',
      hasPhotos: 'PFS Realty Group tiene {1} fotos de este {0}',
      hasPhotos_female: 'PFS Realty Group tiene {1} fotos de esta {0}',
      counties: {
        longTitle: 'Condados en la Florida - PFS Realty Group',
        title: 'Condados en la Florida',
        description:
          'Encuentra propiedades en venta y renta en los condados de la Florida.'
      },
      cities: {
        longTitle: 'Ciudades en {0} - PFS Realty Group',
        title: 'Ciudades en {0}',
        description:
          'Encuentra propiedades en venta y renta en las ciudades de {0}, Florida.'
      },
      neighborhoods: {
        longTitle: 'Vecindarios en {0} - PFS Realty Group',
        title: 'Vecindarios en {0}',
        description:
          'Encuentra propiedades en venta y renta en los vecindarios de {0}, Florida.'
      },
      calculator: {
        longTitle: 'Calculadora de Hipotecas - PFS Realty Group',
        description:
          'Calcula fácilmente tus pagos hipotecarios para propiedades en Florida con nuestra herramienta online.'
      }
    }
  },
  cl: {
    home: {
      rent: 'Alquilar'
    },
    search: {
      buyTypeOptions: {
        rent: 'Alquilar',
        rented: 'Alquilado | Alquilados'
      },
      rentedItems: '{items} alquilados',
      rentedItems_female: '{items} alquiladas',
      soldOrRentedItems: '{items} vendidos o alquilados',
      soldOrRentedItems_female: '{items} vendidas o alquiladas',
      itemsForRent: '{items} en alquiler',
      rentedSearchResults: '{items} alquiladas en {search}',
      rentedSearchResultsNearYou: '{0} alquiladas cerca de tu ubicación',
      rentedResults: 'Propiedades alquiladas'
    },
    property: {
      rented: 'Alquilado',
      rented_female: 'Alquilada'
    },
    project: {
      closedCondosForRent: 'Apartamentos alquilados'
    },
    marketReport: {
      recentlyRented: 'Recientemente alquilados'
    }
  }
};

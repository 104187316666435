import { defineStore } from 'pinia';

function getHeader(req: { headers: Record<string, string> }, header: string) {
  return (
    req?.headers?.[header] ||
    req?.headers?.[header.toLowerCase()] ||
    req?.headers?.[header.toUpperCase()]
  );
}

export const useMainStore = defineStore('main', {
  state: () => ({
    userCountry: undefined as undefined | string,
    currentPathToTranslate: undefined as undefined | string,
    currentTranslationPath: undefined as undefined | string
  }),
  actions: {
    serverSideData(req: { headers: Record<string, string> }) {
      try {
        if (process.server) {
          const keys = [
            'x-pfs-cf-ipcountry',
            'x-vercel-ip-country',
            'CF-IPCountry',
            'http-cf-ipcountry',
            'HTTP_CF_IPCOUNTRY'
          ];

          for (const key of keys) {
            const value = getHeader(req, key);
            if (value) {
              this.userCountry = value;
              break;
            }
          }

          this.userCountry = this.userCountry || null;
        }
      } catch (err) {
        console.error('[SSR] Error loading data', err);
      }
    },
    setTranslationPath(path: string, translationPath: string) {
      this.currentPathToTranslate = path;
      this.currentTranslationPath = translationPath;
    }
  }
});
